import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {UserAccountPreLoginModel} from '../models/user-account.model';
import {UserAccountService} from '../services/user-account.service';

@Injectable({
    providedIn: 'root',
})
export class UserAccountDataService {
    private ngUnsubscribe = new Subject();

    constructor(private userAccountService: UserAccountService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    addPlaceholder(data: any[]) {
        if (data[0] !== undefined && data[0].ID !== null) {
            data.unshift({
                ClientId: null,
                TenantName: '--'
            });
        }
        return data;
    }

    preLoginData(modelData: UserAccountPreLoginModel) {
        return new Promise((resolve, reject) => {
            this.userAccountService.preLogin(modelData)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (res) => {
                        res = this.addPlaceholder(res);
                        resolve(res);
                    }, error:
                        (err) => {
                            console.warn(err);
                            reject(err);
                        }
                });
        });
    }
}

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpUtility } from './utilities/http-utility';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './interceptors/api.interceptor';

// Modules
import { BadgeModule } from 'primeng/badge';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { RouterModule } from '@angular/router';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once.guard';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';

// Components
import { HeaderComponent } from './components/header/header.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { HeaderNavComponent } from './components/header-nav/header-nav.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { DataPerspectiveDropdownComponent } from './components/data-perspective-dropdown/data-perspective-dropdown.component';

// Services
import { AuthService } from './services/auth.service';
import { SidenavService } from './services/sidenave-service';
import { AlertsService } from './services/alerts.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpCacheService } from './services/http-cache.service';
import { BlobStorageService } from './services/blob-storage.service';

@NgModule({
  declarations: [AlertsComponent, HeaderComponent, HeaderNavComponent, SideNavComponent, DataPerspectiveDropdownComponent,],
  exports: [AlertsComponent,HeaderComponent, HeaderNavComponent, SideNavComponent, DataPerspectiveDropdownComponent],
  imports: [HttpClientModule, BadgeModule, CommonModule, ToastModule, MessagesModule, RouterModule, AccordionModule,
    DropdownModule, FormsModule, ButtonModule
  ],
  providers: [HttpClient, HttpUtility, SidenavService, AlertsService,
    AuthService, ConfirmationService, MessageService, HttpCacheService, BlobStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    { provide: 'Window', useFactory: () => window }
  ],
})

export class CoreModule extends EnsureModuleLoadedOnceGuard {    // Ensure that CoreModule is only loaded into AppModule

  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localizedatetime'
})
export class LocalizeDateTimePipe implements PipeTransform {
  transform(dt: string, argDate: boolean, argTime: boolean): string {
    let dateArr = [];
    let rtn = null;
    let newDate;
    let newTime;
    let hours;
    let parsedDate;
    if (dt !== null && dt !== undefined && dt !== '') {
      if (dt && Object.prototype.toString.call(dt) === '[object Date]') {//} && !isNaN(dt)) {
        newDate = new Date(dt);
      } else {
        if (dt.toString().substr(dt.toString().length - 1) === 'Z') {
          dt = dt.toString().replace('Z', '');
        }
        dateArr = dt.toString().split(/[-T:]/);
        newDate = new Date(dateArr[0], Number(dateArr[1]) - 1, dateArr[2], dateArr[3], dateArr[4], dateArr[5]);
      }
      if (newDate.getTime() !== null && newDate.getTime() !== undefined) {
        if (newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() > 0) {
          hours = newDate.getTimezoneOffset() / 60;
          newDate.setHours(newDate.getHours() - hours);
          parsedDate = new Date(Date.parse(newDate));
        } else {
          parsedDate = new Date(Date.parse(newDate));
        }
      } else {
        parsedDate = new Date(Date.parse(newDate));
      }
      if (argDate === true) {
        rtn = ('0' + (parsedDate.getMonth() + 1)).slice(-2) + '/' + ('0' + parsedDate.getDate()).slice(-2) + '/' + parsedDate.getFullYear() + '  ';
      }
      if (argTime === true) {
        newTime = new Date(newDate);
        if (rtn !== null && rtn !== undefined) {
          if (newTime.getHours() > 12) {
            rtn += newTime.getHours() - 12;
          } else {
            rtn += newTime.getHours();
          }
          if (newTime.getMinutes() < 10) {
            rtn += ':0' + newTime.getMinutes();
          } else {
            rtn += ':' + newTime.getMinutes();
          }
          if (newTime.getHours() > 11) {
            rtn += ' PM';
          } else {
            rtn += ' AM';
          }
        } else {
          if (newTime.getHours() > 12) {
            rtn += newTime.getHours() - 12;
          } else {
            rtn += newTime.getHours();
          }
          if (newTime.getMinutes() < 10) {
            rtn += ':0' + newTime.getMinutes();
          } else {
            rtn += ':' + newTime.getMinutes();
          }
          if (newTime.getHours() > 11) {
            rtn += ' PM';
          } else {
            rtn += ' AM';
          }
        }
      }
    } else {
      rtn = '';
    }
    return rtn;
  }
}

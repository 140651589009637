import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../core/services/auth.service';
import {ContextService} from '../../services/context.service';
import {ContextModel} from '../../models/context.model';

@Injectable()
export class AuthGuardService implements CanActivate {
  context: ContextModel;

  constructor(private authService: AuthService, private router: Router, private contextService: ContextService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const accessToken = this.contextService.contextObject.accessToken;
    if (accessToken && accessToken !== 'null') {
      if (!this.contextService.contextObject.UserAgreement && state.url !== '/user-agreement') {
        this.router.navigate(['user-agreement']);
        return false;
      }
      return true;
    }
    console.debug(route, state);
    // Track URL user is trying to go to, so we can send them there after logging in

    //this.authService.redirectUrl = state.url;
    this.router.navigate(['login']);
    return false;
  }
}

<ng-container>
    @if (isLogin) {
        <div class="login-page-background"></div>
    }
    <div [class]="'layout-menu-'+ menuTheme+ ' layout-topbar-'+ topbarTheme" [ngClass]="{'p-input-filled': inputStyle === 'filled', 'p-ripple-disabled': !ripple,
        'layout-static': isStatic(),
        'layout-overlay': isOverlay(),
        'layout-overlay-active': overlayMenuActive && isOverlay(),
        'layout-horizontal': isHorizontal(),
        'layout-static-active': !staticMenuDesktopInactive && isStatic(),
        'layout-mobile-active': staticMenuMobileActive,
        'layout-rtl': isRTL,
        'layout-rightpanel-active': rightPanelActive}" class="layout-wrapper">

        @if (contextService.contextObject.accessToken) {
            <app-topbar></app-topbar>
            <app-rightpanel></app-rightpanel>
            <div class="menu-wrapper" (click)="onMenuClick($event)">
                <div class="layout-menu-container">
                    <div class="p-fluid p-grid">
                        <div class="col-md-12 col-sm-12 col-xs-12 tenant-selector">
                            <p-dropdown [options]="tenantList" optionLabel="Description" optionValue="Id" (onChange)="setTenantData($event)"></p-dropdown>
                        </div>
                    </div>
                    <app-menu [activeMenu]="activeMenu"></app-menu>
                </div>
            </div>
        }

        <div #scrollElement [ngClass]="{'layout-main' : contextService.contextObject.accessToken}">
            <!--      <app-breadcrumb  *ngIf="contextService.contextObject.accessToken"></app-breadcrumb>-->

            <div [ngClass]="{'layout-content' : contextService.contextObject.accessToken}">
                <div [ngStyle]="contextService.contextObject?.selectedTenant !== 10000 && !isEngageAdmin() ? {'background-image': activeTenantLogoUrl, 'background-repeat': 'repeat',
           'background-size' : '200px'} : {'background-image': 'none'}" class="content-background-image"></div>
                @if (contextService.contextObject?.selectedTenant) {
                    <div class="tenant-header">
                        <div class="tenant-logo">
                            <img [src]="activeTenantLogo">
                        </div>
                    </div>
                }
                <div class="router-container">
                    <router-outlet></router-outlet>
                </div>
            </div>

            @if (contextService.contextObject.accessToken) {
                <app-footer></app-footer>
            }
        </div>

        @if (contextService.contextObject.accessToken) {
            <app-config></app-config>
        }

        @if (staticMenuMobileActive) {
            <div class="layout-mask modal-in"></div>
        }

    </div>
</ng-container>

import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from './core/services/auth.service';
import {PrimeNGConfig} from 'primeng/api';
import {MenuService} from './app.menu.service';
import {Subject} from 'rxjs';
import {ContextModel} from './models/context.model';
import {ContextService} from './services/context.service';
import {filter} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {GenericSelectModel} from './models/generic-select.model';
import {TenantModel, TenantModelList} from './models/tenant.model';
import tenantsList from './json/tenants.json';
import menuNoTenant from './json/menu-no-tenant.json';
import menu from './json/menu.json';
import {Location} from '@angular/common';
import {TenantService} from './services/tenant-service.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit, OnInit {
    isAuthenticated: boolean = false;
    context = new ContextModel();
    activeTenant: Subject<any> = new Subject();
    selectedMenuView: string;
    dataPerspectiveDescription: string = null;
    isPublic: boolean;
    toastSave: boolean = false;
    timer;
    public _environment = environment;
    showPrompt: string = 'false';
    userAgreement: boolean = false;
    rotateMenuButton: boolean;
    topbarMenuActive: boolean;
    overlayMenuActive: boolean;
    staticMenuDesktopInactive: boolean;
    staticMenuMobileActive: boolean;
    menuClick: boolean;
    topbarItemClick: boolean;
    activeTopbarItem: any;
    documentClickListener: () => void;
    configActive: boolean;
    configClick: boolean;
    rightPanelActive: boolean;
    rightPanelClick: boolean;
    menuHoverActive = false;
    searchClick = false;
    search = false;
    topbarTheme = 'light';
    menuTheme = 'dim';
    layoutMode = 'light';
    menuMode = 'static';
    isRTL = false;
    inputStyle = 'outlined';
    ripple: boolean;
    inputObjTenant: GenericSelectModel;
    setTenantId: number = 10000;
    tenantOptions: TenantModel[];
    activeTenantLogo: string = '../assets/images/secondary-logo.png';
    activeTenantLogoUrl: string;
    activeTenantName: string;
    activeMenu: any[];
    isLogin: boolean;
    tenantList: TenantModelList[];
    @ViewChild('scrollElement') scrollEl: ElementRef;
    private pushed: boolean = false;

    constructor(public renderer: Renderer2, private menuService: MenuService, private primengConfig: PrimeNGConfig,
                private authService: AuthService, public contextService: ContextService, private changeDetection: ChangeDetectorRef,
                private router: Router, private location: Location, private tenantService: TenantService
    ) {
        const context = {} as ContextModel;
        if (!contextService.contextObject) {
            context.apiBaseUrl = null;
            context.apiBaseUrlV1 = null;
            context.apiBaseUrlV2 = null;
            context.tenantName = null;
            context.tenantLogo = null;
            context.supportEmail = null;
            context.accessToken = null;
            context.isLoggedIn = false;
            context.selectedTenant = this.contextService.contextObject?.selectedTenant;
            if (this.isMobile) {
                context.visibility = 'native';
            } else {
                context.visibility = 'hover';
            }
            this.contextService.contextObject = context;
        } else {
            context.isMobile = false;
        }

        router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe({
            next: (event: NavigationEnd) => {
                if (this.router.url.includes('login')) {
                    this.isLogin = true;
                } else {
                    this.isLogin = false;
                    this.initTenants(false);
                }
            }
        });
    }

    ngOnInit() {
        this.authService.userEmitter.subscribe({
            next: (token) => {
                this.isAuthenticated = token;
            }
        });

        if (this.contextService.contextObject.selectedTenant) {
            this.setTenantId = this.contextService.contextObject.selectedTenant;
        }
        if (this.contextService.contextObject.selectedTenant) {
            this.activeMenu = menu;
            this.activeTenantLogo = tenantsList.find(tenant => tenant.ID === this.contextService.contextObject.selectedTenant).PrimaryLogoURL;
            this.activeTenantName = tenantsList.find(tenant => tenant.ID === this.contextService.contextObject.selectedTenant).TenantName.toLowerCase();
            this.activeTenantLogoUrl = `url("${this.activeTenantLogo}")`;
        }

    }

    ngAfterViewInit() {
        // hides the horizontal submenus or top menu if outside is clicked
        if (!this.contextService.contextObject.selectedTenant) {
            this.activeMenu = menuNoTenant;
        }

        this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
            if (this.contextService.contextObject.isLoggedIn) {
                this.isAuthenticated = true;
                this.dataPerspectiveDescription = this.contextService.contextObject.selOrganizationDesc;
            } else {
                this.isAuthenticated = false;
            }
            this.changeDetection.detectChanges();


            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.menuClick && this.isHorizontal()) {
                this.menuService.reset();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            if (!this.rightPanelClick) {
                this.rightPanelActive = false;
            }

            if (!this.menuClick) {
                if (this.overlayMenuActive) {
                    this.overlayMenuActive = false;
                }
                if (this.staticMenuMobileActive) {
                    this.staticMenuMobileActive = false;
                }

                this.menuHoverActive = false;
                this.unblockBodyScroll();
            }

            if (!this.searchClick) {
                this.search = false;
            }

            this.searchClick = false;
            this.configClick = false;
            this.topbarItemClick = false;
            this.menuClick = false;
            this.rightPanelClick = false;
        });

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe({
                next: (event: NavigationEnd) => {
                    // this.scrollEl.nativeElement.scroll({ top: 0, behavior: 'smooth', block: 'start' });
                    this.scrollEl.nativeElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'nearest'
                    });
                }
            });
    }

    isEngageAdmin(): boolean {
        return this.router.url.includes('/admin/') && this.router.url.includes('/dashboard');
    }

    onMenuButtonClick(event) {
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;
        this.menuClick = true;

        if (this.menuMode === 'overlay' && !this.isMobile()) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }

        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
            if (this.staticMenuMobileActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onMenuClick($event) {
        this.menuClick = true;
    }

    initTenants(disable: boolean): void {
        this.tenantService.getTenants().subscribe({
            next: (data) => {
                this.tenantList = data.Tenants;
            }
        });
        // this.inputObjTenant.data  = tenants;
        // console.log(this.inputObjTenant.data);
        // this.inputObjTenant = Object.assign({}, this.inputObjTenant);
    }

    setTenantData(event): void {
        const context = this.contextService.contextObject;
        if (event.value !== 10000) {
            context.selectedTenant = event.value;
            this.activeMenu = menu;
            this.activeTenantLogo = this.tenantList.find(tenant => tenant.id === event.value).logoUrl;
            this.activeTenantName = this.tenantList.find(tenant => tenant.id === event.value).description.toLowerCase();
            this.activeTenantLogoUrl = `url("${this.activeTenantLogo}")`;
            this.router.navigateByUrl('/tenant-dashboard/' + event.value);
        } else {
            context.selectedTenant = null;
            this.activeMenu = menuNoTenant;
            this.activeTenantLogo = '../assets/images/secondary-logo.png';
            this.activeTenantLogoUrl = null;
            this.router.navigateByUrl('admin/tenants/list');
        }

        this.contextService.contextObject = context;
    }


    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        if (item.className === 'search-item topbar-item') {
            this.search = !this.search;
            this.searchClick = !this.searchClick;
        }

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }


    onRTLChange(event) {
        this.isRTL = event.checked;
    }

    onRippleChange(event) {
        this.ripple = event.checked;
        this.primengConfig.ripple = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    onRightPanelButtonClick(event) {
        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;
        event.preventDefault();
    }

    onRightPanelClick() {
        this.rightPanelClick = true;
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 992;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.menuMode === 'overlay';
    }

    isStatic() {
        return this.menuMode === 'static';
    }

    isHorizontal() {
        return this.menuMode === 'horizontal';
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    public logOut() {
        this.authService.logout();
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
    }

}

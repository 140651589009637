import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';
import {TenantModel, TenantModelGroup, TenantModelList} from '../models/tenant.model';

@Injectable({
    providedIn: 'root',
})
export class TenantService {
    constructor(private http: HttpClient, private contextService: ContextService) {
    }

    getTenants(): Observable<TenantModelGroup> {
        return this.http.get<TenantModelGroup>(`${this.contextService.contextObject.apiBaseUrlV2}/Tenant/GetAll`);
    }
}

import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import {ContextModel} from '../../models/context.model';

@Injectable()
export class HttpUtility {
  headersWithAuth = new HttpHeaders();
  headersNoAuth = new HttpHeaders();
  public token = null;
  context: ContextModel;

  constructor() {

  }

  public setHeaders() {
    console.log(this.context.accessToken);
    this.headersNoAuth = this.headersNoAuth.append('content-type', ' application/x-www-form-urlencoded');
    this.headersNoAuth = this.headersNoAuth.append('Access-Control-Allow-Origin', '*');
    this.headersNoAuth = this.headersNoAuth.append('accept', 'application/json, text/javascript, */*; q=0.01');

    this.headersWithAuth = this.headersWithAuth.append('content-type', ' application/json');
    this.headersWithAuth = this.headersWithAuth.append('Access-Control-Allow-Origin', '*');
    this.headersWithAuth = this.headersWithAuth.append('accept', 'application/json, text/javascript, */*; q=0.01');
    this.headersWithAuth = this.headersWithAuth.append('Authorization', `bearer ${this.context.accessToken}`);
  }
}

@if (root && item.visible !== false) {
  <div>
    <span class="layout-menuitem-text">{{item.label}}</span>
  </div>
}
@if ((!item.routerLink || item.items) && item.visible !== false) {
  <a [attr.href]="item.url" (click)="itemClick($event)" (keydown.enter)="itemClick($event)"
    [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" (mouseenter)="onMouseEnter()" pRipple>
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{item.label}}</span>
    @if (item.items) {
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
    }
  </a>
}
@if ((item.routerLink && !item.items) && item.visible !== false) {
  <a (click)="itemClick($event)"
    routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: !item.preventExact}"
    [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" (mouseenter)="onMouseEnter()" pRipple>
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{item.label}}</span>
    @if (item.items) {
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
    }
  </a>
}
@if (item.visible !== false) {
  <div class="layout-menu-tooltip">
    <div class="layout-menu-tooltip-arrow"></div>
    <div class="layout-menu-tooltip-text">{{item.label}}</div>
  </div>
}
@if (((item.items && root) || (item.items && active)) && item.visible !== false) {
  <ul [@children]="root ? 'visible' : active ? 'visibleAnimated' : 'hiddenAnimated'">
    @for (child of item.items; track child; let i = $index) {
      <li app-menuitem [item]="child" [index]="i" [parentKey]="key" ></li>
    }
  </ul>
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {AuthService} from '../core/services/auth.service';
import {GoogleRecaptchaService} from '../services/google-recaptcha.service';
import {GenericSelectModel} from '../models/generic-select.model';
import {UserAccountPreLoginModel} from '../models/user-account.model';
import {UserAccountService} from '../services/user-account.service';
import {UserAccountDataService} from '../services/user-account-data.service';
import {EnvironmentsService} from '../services/environments.service';
import {ContextModel} from '../models/context.model';
import {ContextService} from '../services/context.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemService} from '../services/system.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
    lForm: UntypedFormGroup;
    loggedIn: boolean = false;
    rememberMe: boolean = false;
    passwordType: string = 'password';
    logo: string;
    isLoading: boolean = false;
    showSignUp: boolean = false;
    needHelp: boolean = false;
    multiTenant: boolean = false;
    showMultiTenant: boolean = false;
    resetButton: boolean = true;
    tenant: string;
    context = new ContextModel();
    refreshPage;
    title: string = 'Engage';
    inputObjTenant: GenericSelectModel;
    setTenantId: string;
    private _userAccountPreLoginModel: UserAccountPreLoginModel;
    private ngUnsubscribe = new Subject();

    constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private userAccountService: UserAccountService,
                private messageService: MessageService, private googleRecaptchaService: GoogleRecaptchaService,
                private userAccountDataService: UserAccountDataService, private environmentsService: EnvironmentsService,
                private activatedRoute: ActivatedRoute, private contextService: ContextService, private router: Router,
                private systemService: SystemService, private titleService: Title
    ) {
        this.lForm = this.formBuilder.group({
            name: new UntypedFormControl(localStorage.getItem('rememberMe'), [Validators.required, Validators.email]),
            password: new UntypedFormControl(null, [Validators.required,
                this.regexValidator(new RegExp('^.*[A-Za-z0-9$@!%*?&].{9,}'), {length: true}),
                this.regexValidator(new RegExp('^.*[a-z]'), {lowercase: true}),
                this.regexValidator(new RegExp('^.*[A-Z]'), {uppercase: true}),
                this.regexValidator(new RegExp('^.*[0-9]'), {number: true}),
                this.regexValidator(new RegExp('^.*[$@!%*?&]'), {special: true}),
                this.regexValidator(new RegExp('^(?!.*([a-zA-Z0-9$@!%^*?&])\\1{2,})'), {repeats: true})
            ]),
            // password: new FormControl('', [Validators.required,
            //   Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])(?!.*([a-zA-Z0-9])\\1{2,})[A-Za-zd$@$!%*?&].{9,}')]),
            showPassword: new UntypedFormControl(false),
            rememberMe: new UntypedFormControl(!!localStorage.getItem('rememberMe'))
        }, {
            validator: this.customValidator('name', 'password')
        });
        sessionStorage.clear();

        this.activatedRoute.queryParams.pipe(take(2)).subscribe({
            next: (params) => {
                this.tenant = params.tenant;
                this.getBaseUrl();
            }
        });
    }

    ngOnInit(): void {
        this.onChanges();
        console.log('environment = ' + environment.environmentName);
        this.refreshPage = setTimeout(() => {
            location.reload();
            console.log('refreshing');
        }, 1680000);
    }

    onChanges(): void {
        this.lForm.controls.showPassword.valueChanges.subscribe({
            next: (val) => {
                this.passwordType = val === true ? 'text' : 'password';
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        clearTimeout(this.refreshPage);
    }

    regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                return null;
            }
            const valid = regex.test(control.value);
            return valid ? null : error;
        };
    }

    login() {
        this.isLoading = true;
        if (this.needHelp === false && !this.lForm.invalid) {
            if (this.lForm.controls.rememberMe.value === true) {
                localStorage.setItem('rememberMe', this.lForm.controls.name.value);
            } else {
                localStorage.removeItem('rememberMe');
            }
            // call on recaptcha v3
            this.googleRecaptchaService.recaptchaChallenge().then(() => {
                // check for multi-tenancy
                if (this.multiTenant && !this.setTenantId) {
                    // present tenant select
                    this._userAccountPreLoginModel = {
                        UserName: this.lForm.controls.name.value,
                        Password: this.lForm.controls.password.value,
                    };
                    this.initTenant(this._userAccountPreLoginModel);
                } else {
                    // continue to signin
                    this.signIn();
                }
            }).catch(() => {
                this.isLoading = false;
            });
        }
    }

    signIn() {
        if (this.needHelp === false && !this.lForm.invalid) {
            this.googleRecaptchaService.recaptchaChallenge().then(() => {
                const stringLogin = 'username=' + this.lForm.controls.name.value +
                    '&password=' + encodeURIComponent(this.lForm.controls.password.value) + '&grant_type=password&client_id=' +
                    'iaff_web';

                this.userAccountService.login(stringLogin)
                    .pipe(take(1))
                    .subscribe({
                        next: (res) => {
                            if (res && res.AccessToken) {
                                this.contextService.setContextPerspective(['MEMBERSHIP']);
                                const contextObject: ContextModel = this.contextService.contextObject;
                                contextObject.accessToken = res.AccessToken;
                                contextObject.clientId = res.LegacyClientId;
                                contextObject.refreshToken = res.RefreshToken;
                                this.contextService.contextObject = contextObject;
                                this.systemService.clearUserCache()
                                    .pipe(take(1))
                                    .subscribe({
                                        next: () => {
                                            // get user meta data to branch user appropriately
                                            this.userAccountService.getUserMetaData()
                                                .pipe(take(1))
                                                .subscribe({
                                                    next: (umData) => {
                                                        
                                                        contextObject.isLoggedIn = true;
                                                        contextObject.isMobile = this.mobile();
                                                        contextObject.UserAgreement = true;
                                                        this.contextService.contextObject = contextObject;
                                                        this.loggedIn = true;
                                                        this.router.navigateByUrl('/dashboard');
                                                        
                                                    }
                                                });
                                        }
                                    });
                            }
                            else
                            {
                                this.messageService.add({severity: 'error', summary: 'Login Failed', detail: "Can't find user."});
                                this.isLoading = false;
                            }
                        }, error: (err) => {
                            if (err.error) {
                                this.messageService.add({severity: 'error', summary: 'Login Failed', detail: err.error.error_description});
                            }
                            this.isLoading = false;
                        }
                    });
            }).catch(() => {
                this.isLoading = false;
            });
        }
    }

    mobile() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
                'Android'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }

    isFormValid() {
        this.resetButton = false;
        if (this.multiTenant && this.inputObjTenant) {
            this.resetButton = true;
            return this.lForm.invalid || this.setTenantId === null;

        } else {
            this.resetButton = true;
            return this.lForm.invalid;
        }
    }

    initTenant(modelData: UserAccountPreLoginModel): any {
        this.inputObjTenant = {
            labelText: 'Client',
            optionValue: 'ID',
            optionLabel: 'TenantName',
            filter: true,
            requiredField: true,
            selectFirstValue: false,
            initSelected: this.context.tenantName,
            data: null
        };
        this.userAccountDataService.preLoginData(modelData).then((lookupData) => {
            this.messageService.add({severity: 'success', summary: 'Client Unknown', detail: 'Please select a client to continue.'});
            this.showMultiTenant = true;
            const regex: RegExp = /ClientId/g;
            const newLookupData = JSON.stringify(lookupData).replace(regex, 'ID');
            this.inputObjTenant.data = JSON.parse(newLookupData);
            this.inputObjTenant = Object.assign({}, this.inputObjTenant);
            this.isLoading = false;
        }).catch(err => {
            if (err && err.error && err.error.error_description) {
                this.messageService.add({severity: 'error', summary: 'Login Failed', detail: err.error.error_description});
            }
            this.isLoading = false;
        });
    }

    setTenantData(event) {
        if (event && event[0]) {
            if (event[0].ID === null) {
                this.setTenantId = null;
                this.showSignUp = false;
            } else {
                this.setTenantId = event[0].ID;
                this.showSignUp = true;
            }
        } else {
            this.setTenantId = null;
            this.showSignUp = false;
        }
        const context = this.contextService.contextObject;

        this.contextService.contextObject = context;
        this.isFormValid();
    }

    getBaseUrl() {
        this.setUrl();
        // if (environment.environmentName === 'demo') {
        //   this.environmentsService.getEnvironment('demo').then((rtnTenant) => {
        //     if (rtnTenant) {
        //       this.setUrl(rtnTenant);
        //     }
        //   });
        // } else if (environment.environmentName === 'dev') {
        //   this.environmentsService.getEnvironment('dev').then((rtnTenant) => {
        //     if (this.tenant) {
        //       this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant2) => {
        //         if (rtnTenant2) {
        //
        //           this.setUrl(rtnTenant2);
        //         }
        //       });
        //     } else {
        //       this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant3) => {
        //         if (rtnTenant3) {
        //           this.setUrl(rtnTenant3);
        //         }
        //       });
        //     }
        //   });
        // } else if (environment.local && environment.environmentName !== 'dev') {
        //   console.log(this.tenant);
        //   if(this.tenant) {
        //     this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
        //       if (rtnTenant) {
        //         this.setUrl(rtnTenant);
        //       }
        //     });
        //   }
        // } else if (!environment.local) {
        //   this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
        //     if (rtnTenant) {
        //       this.setUrl(rtnTenant);
        //     }
        //   });
        // }

    }

    setUrl() {
        const context = {} as ContextModel;
        context.apiBaseUrl = environment.apiBaseURL;
        context.apiBaseUrlV1 = environment.apiBaseUrlV1;
        context.apiBaseUrlV2 = environment.apiBaseUrlV2;
        context.accessToken = null;
        this.titleService.setTitle('Admin Portal');
        sessionStorage.setItem('pageTitle', 'Admin Portal');
        this.contextService.contextObject = context;
        this.multiTenant = this.contextService.contextObject.multiTenant;
        this.logo = this.contextService.contextObject.tenantLogo;
        if (!this.multiTenant) {
            this.setTenantId = this.contextService.contextObject.tenantName;
            this.showSignUp = true;
        }
    }

    customValidator(unControlName: string, pswdControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.controls[unControlName];
            const matchingControl = formGroup.controls[pswdControlName];
            if (matchingControl.errors && !matchingControl.errors.customValidator) {
                return;
            }
            const am: number = control.value.indexOf('@');
            const username: string = control.value.substr(0, am);
            const domain: string = control.value.substr(am + 1, control.value.length);
            if (matchingControl.value.indexOf(username) > -1 || matchingControl.value.indexOf(domain) > -1) {
                matchingControl.setErrors({customValidator: true});
            } else {
                matchingControl.setErrors(null);
            }
        };
    }
}

import {EventEmitter, Injectable, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {UserAccountService} from '../../services/user-account.service';
import {BlobStorageService} from './blob-storage.service';
import {ContextModel} from '../../models/context.model';
import {ContextService} from '../../services/context.service';
import {MessageService} from 'primeng/api';
import {UserAccountMetaDataModel} from '../../models/user-account.model';
import {SystemService} from '../../services/system.service';

@Injectable()
export class AuthService {
    context: ContextModel;
    isAuthenticated: boolean;
    routeTo: string = 'dashboard';
    redirectUrl: string;
    @Output() authChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    private loggedIn = new Subject<any>();
    public userEmitter = this.loggedIn.asObservable();
    private ngUnsubscribe = new Subject();

    constructor(private router: Router, private userAccountService: UserAccountService,
                private blogStorageService: BlobStorageService, private contextService: ContextService,
                private messageService: MessageService, private systemService: SystemService) {
    }

    loggedInEmitChange(isLoggedIn) {
        this.loggedIn.next(isLoggedIn);
    }

    loginSuccess(umData: UserAccountMetaDataModel, linkedAcct: boolean, oktaRedirect: string) {
        const context: ContextModel = this.contextService.contextObject;
        this.isAuthenticated = linkedAcct;
        if (umData) {
            context.UserId = umData.UserId;
            context.UserName = umData.UserName;
            context.TenantId = umData.TenantId;
            context.PersonId = umData.PersonId;
            context.OrganizationId = umData.OrganizationId;
            context.FacebookAppId = umData.FacebookAppId;
            context.EmailVerified = umData.EmailVerified;
            context.LinkLogExists = umData.LinkLogExists;
            context.UserNameEncoded = umData.UserNameEncoded;
            context.TenantIdEncoded = umData.TenantIdEncoded;
            context.Administrator = umData.Administrator;
            context.Active = umData.Active;
            context.TemporaryPassword = umData.TemporaryPassword;
            context.HasSecurityQuestionResponses = umData.HasSecurityQuestionResponses;
            if (umData.Contexts) {
                // remove duplicates
                context.Contexts = umData.Contexts.filter((a, b) => umData.Contexts.indexOf(a) === b);
            } else {
                context.Contexts = [];
            }
            context.SuperUser = umData.SuperUser;
            context.PersonName = umData.PersonName;
        }
        context.pageContexts = [];
        context.selOrganizationId = null;
        context.selectedMOrgId = null;
        context.selectedTOrgId = null;
        context.selectedOOrgId = null;
        context.selOrganizationDesc = null;
        context.selectedMOrgDesc = null;
        context.selectedTOrgDesc = null;
        context.selectedOOrgDesc = null;
        context.loggedIn = new Date().toString();
        this.contextService.contextObject = context;

        this.blogStorageService.getBlobToken().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: (bsData) => {
                if (bsData) {
                    context.blobToken = bsData;
                    this.contextService.contextObject = context;
                    // the linkedAcct variable drives whether or not to display the main header appropriately
                    if (linkedAcct) {
                        this.loggedInEmitChange(bsData);
                        if (localStorage.getItem('redirect') && localStorage.getItem('redirect') !== '') {
                            // redirect and clear value if necessary
                            if (localStorage.getItem('redirect') === 'page-not-found') {
                                this.routeTo = 'dashboard';
                            } else {
                                this.routeTo = 'dashboard';
                            }
                            localStorage.removeItem('redirect');
                        } else if (umData && (umData.TemporaryPassword || !umData.HasSecurityQuestionResponses)) {
                            // redirect to security-questions if necessary
                            this.routeTo = 'security-questions';
                        }
                        // SSO redirect to Okta
                        // if (oktaRedirect && oktaRedirect !== '') {
                        //   if (window.location.hostname === 'localhost') {
                        //     window.location.replace(`${oktaRedirect}http://localhost:4200/${this.routeTo}`);
                        //   } else {
                        //     window.location.replace(`${oktaRedirect}https://${window.location.hostname}/${this.routeTo}`);
                        //   }
                        // } else {
                        //   this.router.navigateByUrl(this.routeTo);
                        // }


                        // context.blobToken = bsData;
                        // this.contextService.contextObject = context;
                        // this.setTenantConfigAll().then((res: tenantConfigsModel[]) => {
                        //   if (res) {
                        //     const context1: ContextModel = this.contextService.contextObject;
                        //     context1.tenantConfigs = res;
                        //     this.contextService.contextObject = context1;
                        //     this.setRootOrgId('membership').then((res2: number) => {
                        //       if (res2) {
                        //         const context2: ContextModel = this.contextService.contextObject;
                        //         context2.RootMemberOrgID = res2;
                        //         this.contextService.contextObject = context2;
                        //         this.setRootOrgId('training').then((res3: number) => {
                        //           if (res3) {
                        //             const context3: ContextModel = this.contextService.contextObject;
                        //             context3.RootTrainingOrgID = res3;
                        //             this.contextService.contextObject = context3;
                        //             this.setRootOrgId('organize').then((res4: number) => {
                        //               if (res4) {
                        //                 const context4: ContextModel = this.contextService.contextObject;
                        //                 context4.RootOrganizeOrgID = res4;
                        //                 this.contextService.contextObject = context4;
                        //               }
                        //             });
                        //           }
                        //         });
                        //       }
                        //     });
                        //   }
                        // });
                    }
                }
            }
        });
    }


    public logout() {
        sessionStorage.clear();
        this.loggedInEmitChange(false);
        location.reload();
        // this.userAccountService.logout().pipe(takeUntil(this.ngUnsubscribe)).subscribe({next:() => {
        //   this.systemService.clearUserCache().pipe(takeUntil(this.ngUnsubscribe)).subscribe({next:() => {
        //     sessionStorage.clear();
        //     this.loggedInEmitChange(false);
        //     //this.router.navigateByUrl('/login');
        //     if (environment.environmentName === 'staging' && environment.local === true) {
        //       window.location.replace('/login?tenant=IUPAT');
        //     } else {
        //       window.location.replace('/login');
        //     }
        //     console.log('in logout');
        //     location.reload();
        //   }});
        // }});
    }

    public authenticationRenew(renewLogin: string) {
        this.userAccountService.refreshLogin(renewLogin)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: (res) => {
                console.log(res);
                const contextObject: ContextModel = this.contextService.contextObject;
                contextObject.accessToken = res.accessToken;
                contextObject.refreshToken = res.refresh_token;
                this.contextService.contextObject = contextObject;
            }, error: (err) => {
                if (err.error) {
                    this.messageService.add({severity: 'error', summary: 'Renew Token Failed', detail: err.error.error_description});
                    //Do this instead of logout because logout() causes a 401 loop
                    sessionStorage.clear();
                    this.loggedInEmitChange(false);
                    this.router.navigateByUrl('/login');
                }
            }
        });
    }

    // private handleError(error: HttpErrorResponse) {
    //   if (error.error instanceof Error) {
    //     console.log(error);
    //     const errMessage = error.error.message;
    //     return throwError(errMessage);
    //     // return Observable.throw(err.text() || 'backend server error');
    //   }
    //   return throwError('error');
    // }
}

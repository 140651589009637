import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './shared/guards/can-activate.guard';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { PreloadModulesStrategy } from './core/strategies/preload-modules.strategy';



const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/dashboard' },

  { path: 'login', data: { preload: true }, loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },

  { path: 'dashboard', canActivate: [AuthGuardService],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },

  { path: 'admin/tenants', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenants/tenants.module').then(m => m.TenantsModule)
  },

  { path: 'admin/menus', canActivate: [AuthGuardService],
    loadChildren: () => import('./all-menus/all-menus.module').then(m => m.AllMenusModule)
  },

  { path: 'admin/admin-users', canActivate: [AuthGuardService],
    loadChildren: () => import('./admin-users/admin-users.module').then(m => m.AdminUsersModule)
  },

  { path: 'tenant-contacts', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-contacts/tenant-contacts.module').then(m => m.TenantContactsModule)
  },

  { path: 'tenant-communication-usage', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-communication-usage/tenant-communication-usage.module').then(m => m.TenantCommunicationUsageModule)
  },

  { path: 'tenant-users', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-users/tenant-users.module').then(m => m.TenantUsersModule)
  },

  { path: 'tenant-menus', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-menus/tenant-menus.module').then(m => m.TenantMenusModule)
  },

  { path: 'vendor-settings', canActivate: [AuthGuardService],
    loadChildren: () => import('./vendor-settings/vendor-settings.module').then(m => m.VendorSettingsModule)
  },

  { path: 'tier', canActivate: [AuthGuardService],
    loadChildren: () => import('./tier/tier.module').then(m => m.TierModule)
  },

  { path: 'branding', canActivate: [AuthGuardService],
    loadChildren: () => import('./branding/branding.module').then(m => m.BrandingModule)
  },

  { path: 'tenant-dashboard', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-dashboard/dashboard.module').then(m => m.DashboardModule) },

  { path: 'page-not-found', canActivate: [AuthGuardService],
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },

  { path: '**', pathMatch: 'full', redirectTo: '/page-not-found' } // catch any unfound routes and redirect to home page


];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { preloadingStrategy: PreloadModulesStrategy }) ],
  exports: [ RouterModule ],
  providers: [PreloadModulesStrategy, AuthGuardService, CanDeactivateGuard]
})
export class AppRoutingModule {

}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AppComponent} from './app.component';


@Component({
    selector: 'app-menu',
    template: `
        <ul class="layout-menu">
          @for (item of activeMenu; track item; let i = $index) {
            <li app-menuitem [item]="item" [index]="i" [root]="true"></li>
          }
        </ul>
        `
})

export class AppMenuComponent implements OnInit, OnChanges {
    @Input() activeMenu: any[];

    constructor(public app: AppComponent) {}

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(this.activeMenu);
    }
}

import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ToastModule } from 'primeng/toast';
import { SharedModule} from '../shared/shared-module';
import { LoginRoutingModule } from './login-routing.module';

@NgModule({
  imports: [ ReactiveFormsModule, RecaptchaV3Module, ToastModule, SharedModule, LoginRoutingModule ],
  declarations: [ LoginRoutingModule.components ],
  providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdPAREaAAAAAPDjF6ZnZDYJmmmdoabb31FDlhMJ' }]
})
export class LoginModule { }

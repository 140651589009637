import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


// Modules
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared-module';
import { LoginModule } from './login/login.module';
import { RouterModule } from '@angular/router';
import { ChartModule } from 'primeng/chart';
import {ProductService} from './service/productservice';
import {ConfigService} from './service/app.config.service';
import {CountryService} from './service/countryservice';
import {CustomerService} from './service/customerservice';
import {EventService} from './service/eventservice';
import {IconService} from './service/iconservice';
import {NodeService} from './service/nodeservice';
import {PhotoService} from './service/photoservice';
import {MenuService} from './app.menu.service';
import {AppBreadcrumbService} from './app.breadcrumb.service';
import {AppConfigComponent} from './app.config.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppRightPanelComponent} from './app.rightpanel.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {ChartsDemoComponent} from './view/chartsdemo.component';


@NgModule({
  declarations: [
    AppComponent,
    AppConfigComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppRightPanelComponent,
    AppBreadcrumbComponent,
    AppTopBarComponent,
    AppFooterComponent,
    ChartsDemoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    RouterModule,
    ChartModule,
  ],
  providers: [
    Title,
    ProductService,
    ConfigService,  CountryService, CustomerService, EventService, IconService, NodeService,
    PhotoService, MenuService, AppBreadcrumbService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,
  HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { HttpCacheService } from '../services/http-cache.service';
import {RenewTokenModel} from '../../models/renew-token.model';
import { UserAccountRefreshLoginModel } from '../../models/user-account.model';
import { ContextService } from '../../services/context.service';
import { ContextModel } from '../../models/context.model';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  context: ContextModel;
  noAuthExcluded: string[];
  externalExcluded: string[];
  fileRequestExcluded: string[];
  externalPrevent: string[];
  constructor(private router: Router, private httpCacheService: HttpCacheService,
              private authService: AuthService, private contextService: ContextService) {

    this.noAuthExcluded = ['/token'];
    this.externalExcluded = ['api.smartystreets.com'];
    this.externalPrevent = ['/dymo/dls/printing/statusconnected'];
  }

  checkExcluded(req, list): boolean {
    let isExcluded: boolean = false;
    list.forEach(item => {
      if (req.url.includes(item)) {
        isExcluded = true;
      }
    });
    //console.log(req,   '--- ' + isExcluded);
    return isExcluded;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const context = this.contextService.contextObject;

    // Add authentication
    if (!this.contextService.contextObject) {
      req = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          Accept: 'application/json,text/xml',
        }
      });
    }
    // External links
    else if (this.checkExcluded(req, this.externalExcluded)) {
        req = req.clone({
            method: 'GET',
            responseType: 'json'
        });
    // }
    // else if (this.contextService.contextObject && this.checkExcluded(req, this.fileRequestExcluded)) {
    //   req = req.clone({
    //     setHeaders: {
    //       method: 'GET',
    //       responseType: 'arraybuffer',
    //       Authorization: `bearer ${this.contextService.contextObject.accessToken}`,
    //     }
    //   });
    } else {
        req = req.clone({
          setHeaders: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            //'Content-Type': 'application/json',
            Accept: 'application/json,text/xml',
            Authorization: `bearer ${this.contextService.contextObject.accessToken}`
          }
      });
    }

    // Add the appropriate organizationid to the query string if organization data perspective is in use
    if (context.selOrganizationId) {
      req = req.clone({
        params: (req.params ? req.params : new HttpParams())
          .set('organizationid', context.selOrganizationId)
      });
    }

    // Add the appropriate personid to the query string if person data perspective is in use
    if (context.selPersonId) {
      req = req.clone({
        params: (req.params ? req.params : new HttpParams())
          .set('personid', context.selPersonId)
      });
    }

    // Cache appropriately
    if (req.method === 'GET') {
      switch(true) {
        //TODO Brad temp comment out for testing new dist list
        //case req.url.toLocaleLowerCase().indexOf('/common/') > -1:
        case req.url.toLocaleLowerCase().indexOf('/interface/getapplist') > -1:
        case req.url.toLocaleLowerCase().indexOf('/interface/getobjectlist') > -1:
        case req.url.toLocaleLowerCase().indexOf('/interface/help/grid') > -1:
        case req.url.toLocaleLowerCase().indexOf('/resource/') > -1:
        case req.url.toLocaleLowerCase().indexOf('/tenantconfig/') > -1 && req.url.toLocaleLowerCase().indexOf('/tenantconfig/rssfeed/') <= 0:
          // eslint-disable-next-line no-case-declarations
          const cachedResponse: HttpResponse<any> = this.httpCacheService.get(req.urlWithParams);

          if (cachedResponse) {
            //committing for now cause hitting lots of api for selects and making hard to debug BK
            //console.log(`Returning cached data for: ${cachedResponse.url}`);
            return of(cachedResponse);
          }

          return next.handle(req)
            .pipe(
              tap(event => {
                if (event instanceof HttpResponse) {
                  this.httpCacheService.put(req.urlWithParams, event);
                }
              })
            );
      }
    }

    // Make Api call and handle errors
    if (!this.checkExcluded(req, this.externalPrevent)) {
      return next.handle(req)
        .pipe(
          tap(() => {
            },
            (err: any) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                  // const renewToken = {} as UserAccountRefreshLoginModel;
                  // const contextObject: ContextModel = this.contextService.contextObject;
                  // renewToken.refresh_token = contextObject.refreshToken;
                  // renewToken.client_id = contextObject.clientId;
                  // renewToken.grant_type = 'refresh_token';
                  // const stringLogin = 'refresh_token=' + encodeURIComponent(renewToken.refresh_token) +
                  //   '&grant_type=' + renewToken.grant_type + '&client_id=' +
                  //   renewToken.client_id;
                  // this.authService.authenticationRenew(stringLogin);

                }
              }
            }
          )
        );
    }
  }
}
